import React, { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { HandleFetchType, handleFetch } from './fetch';
import { useAuthContext } from '../auth/AuthContext';

type UseFetchType = HandleFetchType & { auto?: boolean };

export function useFetch({ auto, ...rest }: UseFetchType = {}) {
	const [, setAuth] = useAuthContext();

	const [fetchState, setFetchState] = React.useState<any>({ loading: false });

	const fetch = React.useCallback(
		(request: any) => handleFetch({ ...request, setAuth }),
		[setAuth],
	);

	const initialFetch = React.useCallback(
		(request: any) => handleFetch({ ...request, setState: setFetchState, setAuth }),
		[setFetchState, setAuth],
	);

	const [initialProps] = React.useState<HandleFetchType>(rest);
	const refetch = React.useCallback(() => initialFetch(initialProps), [initialProps, initialFetch]);

	React.useEffect(() => {
		if (auto) refetch();
	}, [auto, refetch]);

	return { fetch, state: fetchState, refetch };
}

export function useAPI(e) {
	return { fetchAPI: useFetch(e).fetch };
}

export function useMultiFieldHandler<T extends Record<string, unknown>>(
	setState: React.Dispatch<React.SetStateAction<T>>,
) {
	return React.useCallback(
		(e: ChangeEvent<any>) =>
			setState((prevState) => ({
				...prevState,
				[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			})),
		[setState],
	);
}

export function useIdRoute() {
	return useParams<{ id: string }>().id;
}

import React from 'react';
import { css } from '@emotion/css/macro';
import { Global, css as reactCss } from '@emotion/react';
import { HubConnectionState } from '@microsoft/signalr';
import Login from './features/Login';
import Portal from './features/Portal';
import AuthContextWrapper, { useAuthContext } from './modules/auth/AuthContext';
import Loading from './modules/core/components/Loading';
import { CONFIG } from './modules/core/constants/config';
import { hub } from './modules/hub/constants';

const globalClass = reactCss`
	body {
		background: url(bg-pattern.png);
		background-size: 100px;
	}
`;

const rootClass = css`
	display: flex;
	min-height: 100vh;
	flex-direction: column;
`;

const buildClass = css`
	text-align: center;
	color: white;
	font-size: 11px;
	padding-bottom: 8px;
`;

function Main() {
	const [auth] = useAuthContext();

	React.useEffect(() => {
		if (auth.isAuthenticated) {
			if (hub.state === HubConnectionState.Disconnected) hub.start();
		} else if (hub.state === HubConnectionState.Connected) hub.stop();
	}, [auth.isAuthenticated]);

	return (
		<React.Suspense fallback={<Loading style={{ color: 'white' }} />}>
			{auth.isAuthenticated ? <Portal /> : <Login />}
		</React.Suspense>
	);
}

export default function App() {
	return (
		<>
			<Global styles={globalClass} />
			<main className={rootClass}>
				<AuthContextWrapper>
					<Main />
				</AuthContextWrapper>
				<div className={buildClass}>{CONFIG.buildInfo}</div>
			</main>
		</>
	);
}

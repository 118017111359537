import React, { Reducer } from 'react';
import { HubAction, HubActionType, HubContextType, HubState } from './types';
import { hub, HubTriggers } from './constants';

const initialState: HubState = {
	supportMessages: [],
	unreadMessageIds: [],
};

export const HubContext = React.createContext<HubContextType>([initialState, () => undefined]);

function reducer(state: HubState, action: HubAction) {
	switch (action.type) {
		case HubActionType.RECEIVE_SUPPORT_EVENT: {
			const { readMessageIds = [], unreadMessageIds = [], message } = action.payload;
			const newMessages = [...state.supportMessages];
			const newUnreadIds = [...state.unreadMessageIds];

			if (message) {
				newMessages.push(message);
				newUnreadIds.push(message.id);
				if (message.isNewTicket)
					// eslint-disable-next-line no-alert
					alert(`There's new ticket #${message.supportTicketId}!`);
			}

			if (readMessageIds && readMessageIds.length > 0)
				readMessageIds.forEach((id) => {
					const unreadIdIndex = newUnreadIds.indexOf(id);

					if (unreadIdIndex < 0) return;

					newUnreadIds.splice(unreadIdIndex, 1);
				});

			if (unreadMessageIds && unreadMessageIds.length > 0)
				unreadMessageIds.forEach((id) => {
					if (newUnreadIds.indexOf(id) < 0) newUnreadIds.push(id);
				});

			return {
				...state,
				supportMessages: newMessages,
				unreadMessageIds: newUnreadIds,
			};
		}
		default:
			return state;
	}
}

function hookHubEvents(dispatch: any) {
	hub.on(HubTriggers.ReceiveSupportEvent, (event: string) =>
		dispatch({
			type: HubActionType.RECEIVE_SUPPORT_EVENT,
			payload: JSON.parse(event),
		}),
	);
}

export function HubContextProvider({ children }: { children: React.ReactNode }) {
	const [state, dispatch] = React.useReducer<Reducer<HubState, HubAction>>(reducer, initialState);

	const dispatchWrapper = React.useCallback((args) => {
		dispatch(args);
		// eslint-disable-next-line no-console -- Development only
		if (process.env.NODE_ENV === 'development') console.log(args);
	}, []);
	const stateDispatch: HubContextType = React.useMemo(
		() => [state, dispatchWrapper],
		[dispatchWrapper, state],
	);

	React.useEffect(() => {
		hookHubEvents(dispatchWrapper);
	}, [dispatchWrapper]);

	return <HubContext.Provider value={stateDispatch}>{children}</HubContext.Provider>;
}

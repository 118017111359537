import { HubConnectionBuilder } from '@microsoft/signalr';
import { AUTH_TOKEN_KEY } from '../core/constants';
import { AccessToken } from './types';

//ICentralHub.cs - what client receives
export enum HubTriggers {
	ReceiveSupportEvent = 'ReceiveSupportEvent',
}

export const hub = new HubConnectionBuilder()
	.withUrl('/hub', {
		accessTokenFactory(): AccessToken {
			return localStorage.getItem(AUTH_TOKEN_KEY) || '';
		},
	})
	.withAutomaticReconnect({
		nextRetryDelayInMilliseconds: () => 1000 * 10,
	})
	.build();

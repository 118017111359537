import React from 'react';
import { useFetch } from '../core/hooks';
import Loading from '../core/components/Loading';

export type AuthStateType = {
	isAuthenticated?: boolean;
	name?: string;
	email?: string;
	imageUrl?: string;
};

export type AuthContextType = [AuthStateType, React.Dispatch<React.SetStateAction<AuthStateType>>];

export const authContextInitial: AuthContextType = [{}, () => undefined];

export const AuthContext = React.createContext<AuthContextType>(authContextInitial);

export function useAuthContext(): AuthContextType {
	return React.useContext(AuthContext);
}

type AuthContextWrapperProps = {
	children: React.ReactNode;
};

export default function AuthContextWrapper({ children }: AuthContextWrapperProps) {
	const [auth, setAuth] = React.useState<AuthStateType>({});

	const { state } = useFetch({
		query: 'Account/Get',
		auto: true,
		onResponse: (response) => {
			if (response.status === 401) setAuth({ isAuthenticated: false });
		},
		onSuccess: (response) => {
			const { name, email, picture } = response.data;
			setAuth({ name, email, imageUrl: picture, isAuthenticated: true });
		},
	});

	const context: AuthContextType = React.useMemo(() => [auth, setAuth], [auth]);

	if (auth.isAuthenticated === undefined || state.loading) return <Loading />;

	return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

import { Dispatch } from 'react';

export type HubContextType = [HubState, Dispatch<HubAction>];

export type AccessToken = string | Promise<string>;

export type HubSupportMessage = {
	id: number;
	supportTicketId: string;
	isNewTicket?: boolean;
	text: string;
	createdDate?: string;
	readDate?: string;
	attachments?: { id?: number; name?: string }[];
};

export type HubSupportEvent = {
	message: HubSupportMessage;
	readMessageIds: number[];
	unreadMessageIds: number[];
};

export type HubState = {
	supportMessages: HubSupportMessage[];
	unreadMessageIds: number[];
};

export enum HubActionType {
	RECEIVE_SUPPORT_EVENT = 'RECEIVE_SUPPORT_EVENT',
}

export type HubAction = {
	type: HubActionType.RECEIVE_SUPPORT_EVENT;
	payload: HubSupportEvent;
};

import createTheme from '@material-ui/core/styles/createTheme';
import { createTheme as newCreateTheme } from '@mui/material';
import { green } from './constants';

export const theme = createTheme({
	typography: {
		htmlFontSize: 17,
		fontFamily:
			'-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
	},
	palette: {
		primary: {
			main: green,
		},
		secondary: {
			main: '#111111',
		},
	},
	overrides: {
		MuiTabs: {
			root: { color: 'white' },
			indicator: { background: 'white' },
		},
		MuiSvgIcon: {
			colorSecondary: { color: 'white' },
		},
		MuiCircularProgress: { root: { color: 'white' } },
	},
});

export const newTheme = newCreateTheme({
	typography: {
		htmlFontSize: 17,
		fontFamily:
			'-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
	},
	palette: {
		primary: {
			main: green,
		},
		secondary: {
			main: '#111111',
		},
	},
	components: {
		MuiSvgIcon: {
			styleOverrides: { colorSecondary: { color: 'white' } },
		},
	},
});

import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { css } from '@emotion/css';

const loadingClass = css`
	margin: auto;
`;

export default function Loading(props: CircularProgressProps) {
	return <CircularProgress {...props} className={loadingClass} />;
}

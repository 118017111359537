import { stringify } from 'query-string';
import { AUTH_KEY } from '../auth/constants';
import { AuthStateType } from '../auth/AuthContext';
import { setAuthToken } from '../auth/helpers';

export type RequestType = {
	query?: string;
	method?: 'GET' | 'POST';
	params?: Record<string, unknown>;
	qs?: Record<string, string | number>;
};

async function fetchAPI({ query = '', method = 'GET', params, qs }: RequestType) {
	const { response, status }: any = await new Promise((resolve) => {
		const req = new XMLHttpRequest();
		req.open(method, `api/${query}${qs ? `?${stringify(qs)}` : ''}`);
		req.setRequestHeader('Content-Type', 'application/json');
		const auth = localStorage.getItem(AUTH_KEY);
		if (auth) req.setRequestHeader('Authorization', `Bearer ${auth}`);
		req.onload = () => resolve(req);
		req.send(params && JSON.stringify(params));
	});

	return { status, ...(response ? JSON.parse(response) : {}) };
}

export type HandleFetchType = {
	setAuth?: (args: AuthStateType) => void;
	setState?: (args: any) => void;
	onResponse?: (response: any) => void;
	onSuccess?: (response: any) => void;
	onError?: (response: any) => boolean;
} & RequestType;

export async function handleFetch({
	setAuth,
	setState,
	onSuccess,
	onError,
	onResponse,
	...rest
}: HandleFetchType) {
	if (setState) setState((prevState: any) => ({ ...prevState, loading: true }));
	const result = await fetchAPI(rest);

	switch (result.status) {
		case 403:
		case 401: {
			if (setAuth) setAuth({ isAuthenticated: false });
			setAuthToken();
			break;
		}
		default:
			break;
	}

	if (onResponse) onResponse(result);
	if (result.success) {
		if (onSuccess) onSuccess(result);
	}
	if (result.error || result.errors) {
		let blockAlert = false;
		if (onError) blockAlert = onError(result);
		if (!blockAlert) {
			// eslint-disable-next-line no-alert -- It's not really an app for wide distribution, hence alert is ok
			alert(result.error || JSON.stringify(result.errors));
		}
	}
	if (setState) setState(() => ({ ...result, loading: false }));
}

import { AUTH_KEY } from './constants';

export function getAuthToken() {
	return localStorage.getItem(AUTH_KEY);
}

export function setAuthToken(token?: string) {
	if (!token) {
		localStorage.removeItem(AUTH_KEY);
		return;
	}
	localStorage.setItem(AUTH_KEY, token);
}
